.hero{
    justify-content: space-between;
    display: flex;
    width: 100%;
    
    
}
.left-h{
    padding: 2rem;
    padding-top: 1.5rem ;
    flex: 3;
    gap: 4rem;
   
    
}
.right-h{
    flex:1;
    position: relative;
    background-color: var(--orange);

}
.the-best-ad{
    display: flex;
    margin-top: 4rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: whitesmoke;
    align-items: center;
    position: relative;
    
}
.the-best-ad>span{
    z-index: 2;
}
.the-best-ad>div{
    background-color: var(--orange);
    height: 80%;
    width: 4.6rem;
    border-radius: 4.5rem;
    left: 8px;
    position: absolute;
    z-index: 1;
}
.hero-text{
    display: flex;
    flex-direction: column;
    color: whitesmoke;
    font-size: 4rem;
    font-weight: bold;
    gap: 1rem;
    

}

.hero-text>:nth-child(3)>span{
    font-size: 1.3rem;
    font-weight: 250;
    letter-spacing: 1px;
    text-transform: none;
    width: 80%;
    
}
.figures{
    display: flex;
    gap: 2rem;
}
.figures>div{
    display: flex;
    flex-direction: column;
}
.figures>div>span:nth-of-type(1){
    color: whitesmoke;
    font-size: 2rem;
}
.figures>div>span:nth-of-type(2){
    color:var(--gray);
    font-size: 2rem;
    text-transform: uppercase;
}

.hero-buttons{
    display: flex;
    gap:1.5rem;
    margin-top: 1rem;
}

.hero-buttons>:nth-last-of-type(1){
    background-color: var(--orange);
    width: 8rem;
    color: whitesmoke;
}
.hero-buttons>:nth-last-of-type(1):hover{
    background-color:transparent;
    border: 2px solid var(--orange);
   
}
.hero-buttons>:nth-last-of-type(2){
    background-color:transparent;
    border: 2px solid var(--orange);
    width: 8rem;
    color: whitesmoke;
    
}
.hero-buttons>:nth-last-of-type(2):hover{
    background-color:var(--orange);
    border: 3px solid var(--darkGrey);
    width: 8rem;
    
   
    

}
.right-h>.btn{
    position: absolute;
    right: 2rem;
    top: 3rem;
}
.right-h>.btn:hover{
    background-color:var(--darkGrey);

    color: whitesmoke;
}

.heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    align-items: start;
    position: absolute;
    border-radius: 10px;
    top: 8rem;
    left: 12rem;
}
.heart-rate>:nth-child(2){
    color: var(--gray);
}
.heart-rate>:nth-child(3){
    color: whitesmoke;
    font-size: 1.5rem;
}
.heart-rate>img{
    width: 2rem;
}
.hero-image{
    width: 20rem;
    position: absolute;
    right: 8rem;
    top: 12rem;
    
}
.hero-image-back{
    width: 15rem;
    position: absolute;
    right: 20rem;
    top: 4rem;
    z-index: -1;
}
.calories{
    display: flex;
    gap: 2rem;
    position: absolute;
    background-color: var(--caloryCard);
    border-radius: 5px;
    width: 12rem;
    padding: 1rem;
    top: 36rem;
    right: 25rem ;
}
.calories>img{
    width: 3rem;
}
.calories>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.calories>div>:nth-child(1){
    color: var(--gray);
}
.calories>div>:nth-child(2){
    color:whitesmoke;
}


.hero-blur{
    width: 22rem;
    height: 30rem;
    left: 0 ;
}
@media screen and (max-width: 768px) {
    .hero{
        flex-direction: column;

    }
    .figures{
        display: flex;
        flex-direction: column;
    }
    .hero-blur{
        width: 14rem;
    }
    .the-best-ad{
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
        margin-left: 65px;
    }
    .hero-text{
        font-size: xx-large;
        align-items: center;
        justify-content: 0;
    }
    .hero-text > div:nth-of-type(3){
        font-size: small;
        font-weight: 200;
        text-align: center;
        letter-spacing: 1px;
    }
    .hero-buttons{
        justify-content: center;
    }
    .figures > div > span :nth-of-type(1){
        font-size: large;
    }
    .figures > div > span :nth-of-type(2){
        font-size: small;
    }
    .right-h{
        position: relative;
        background: none;
        
        
    }
    .heart-rate{
        left:1rem;
        background-color: transparent;
     
       
        
    }
    .calories{
        position: relative;
        top: 4rem;
        left: 5rem;
       
        
        
    }
    .right-h>button{
       margin-right: 250px;
    
        
    }
    .calories>img{
        width: 2rem;
    }
    .calories > div >:nth-child(2){
        color: whitesmoke;
        font-size: 2rem; 
        
    }
    .hero-image{
        position: relative;
        width: 15rem;
        left: 12rem;
        top: 1rem;
        align-self: center;
    }
    .hero-image-back{
        width: 15rem;
        left: 8rem;
        top: -2rem;
    }
  }