.join{
    display: flex;
    padding: 0;
    gap: 10rem; 
    margin-top: -20rem;
    margin-bottom: 3rem;
}
.left-j{
    color: whitesmoke;
     font-size: 3rem;
     font-weight: bold;
     text-transform: uppercase;
     position: relative;
     padding: 0rem 1rem;
}
.left-j>div:nth-child(1)>span:nth-child(1){
    border-top: solid orange 3px ;
    
    
}
.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    
}
.email-container{
    display: flex;
    background-color: gray;
    gap: 3rem;
    padding: 1.5rem;
    
    
}
.email-container>input{
    background-color: transparent;
    outline: none;
    border: none;
    outline: none;
    color: var(--lightgray);
    
    
}
.btn-j{
    background-color: var(--orange);
    color: whitesmoke;
    
    
}

::placeholder{
    color: var(--lightgray);
}



@media screen and (max-width:768px ) {
    .join{
        flex-direction: column;
        gap: 1rem;
    }
    .left-j{
        font-size: x-large;
        flex-direction: column;
    }
    .right-j{
        padding: 2rem;
    }
}
