.Footer-container{
    position: relative;
    
}
.Footer-container>hr{
    border: 1px solid var(--lightgray);
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    gap: 4rem;
    justify-content: space-between;
    width: 15rem;
    margin-left: 37rem;
    margin-top: 5rem;
    
    
    
}

.footer>img{
    width: 2rem;
    height: 2rem;
}
.logo-f>img{
    width: 15rem  ;
    margin-left: 40rem;
    margin-top: 2rem;
    margin-bottom: 10rem;
    
    
}
.blur-footer1{
    bottom: 0;
    width: 26rem;
    right: 15%;
    height: 12rem;
    filter: blur(200px);
    background-color: red;

    
}
.blur-footer2{
    bottom: 0;
    width: 26rem;
    left: 15%;
    height: 12rem;
    filter: blur(200px);
    background-color: rgb(255,115,0);
}
@media screen and (max-width:768px ) {
.footer{
    margin-left: 80px;
    
}
.logo-f>img{

    margin-left: 120px;
}

}
 